import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import {
  GetAllItems,
  DefaultSelectCurrencyValue
} from "../../utility/Form";
import CommonAddItemComp from "../common/Add";
import Select from 'react-select';
import { set, isEmpty, get, merge } from "lodash";
// import { v4 as uuidv4 } from 'uuid';
// const FORM_ID = uuidv4();
import FormFooter from "../common/FormFooter";
import CurrencyJson from "../../config/currency.json"

const AddFormControls = (props) => {
  const [editItem, setEditItem] = useState(DefaultSelectCurrencyValue);
  const [currencyOption, setCurrencyOption] = useState(DefaultSelectCurrencyValue);
  set(props, 'additionalParams.fields.upsert', true);

  //Get settings
  const GetSettings = async () => {
    try {
      const { data } = await GetAllItems('settings.getAll', { query: "_limit=-1" });
      setEditItem(data?.results[0])
      set(props, 'additionalParams.fields.id', data?.results[0]?.id);
    } catch (error) {
      console.log('AddFormControls GetBuildingManagers error', error);
    }
  };

  useEffect(() => {
    GetSettings();
  }, []);

  useEffect(() => {
    editItem?.currency && setCurrencyOption(editItem.currency)
  }, [editItem]);


  const FormSubmitFunc = async (event, additionalParams = {}) => {
    try {
      if (isEmpty(get(props, 'additionalParams.fields.currency'))) {
        set(props, 'additionalParams.fields.currency', DefaultSelectCurrencyValue)
      }
      const Result = await props.HandleSubmitFunc(event, {
        formId: 'setting-form',
        ...merge(additionalParams, { isPageRedirect: true, })
      });
      if (get(Result, 'formSubmit.hasError')) {
        //setFromError(Result.formSubmit.error);
      }
    } catch (error) {
      console.log('FormSubmitFunc error', error);
    }
  }

  const HandleMultiSelect = (event, name, copyInAddParam) => {
    if (copyInAddParam) {
      const SelectedOptions = event.map(option => option.value)
      set(props, `additionalParams.fields.${name || 'extra.select'}`, SelectedOptions);
    }
    switch (name) {
      case 'currency':
        setCurrencyOption(event);
        set(props, 'additionalParams.fields.currency', event);
        break;
      default:
        break;
    }
  }

  return (
    <>
      <Form id="setting-form" noValidate onSubmit={(event) => FormSubmitFunc(event, {
        isSubmit: true
      })}>
        <Row className="mb-4">
          <Col lg={6} className="mb-4">
            <div className="form-wrapper">
              <label>Currency</label>
              <div className="custom-selectbox-form selectbox-dropdown">
                <Select
                  name="currency"
                  options={CurrencyJson}
                  placeholder="Please select"
                  classNamePrefix={"select-opt"}
                  isSearchable={false}
                  value={currencyOption}
                  onChange={(event) => HandleMultiSelect(event, 'currency')}
                />
              </div>
            </div>
          </Col>
        </Row>
        <FormFooter parentPath={props.parentPath}></FormFooter>
      </Form>
    </>
  )
}

const EditSettings = (props) => {
  return <CommonAddItemComp PuFormControlsAdd={
    (HandleSubmitFunc) => {
      return (
        <>
          <AddFormControls
            HandleSubmitFunc={HandleSubmitFunc}
            {...props}
          />
        </>
      )
    }
  } {...props} />
}

export default EditSettings;
